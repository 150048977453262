import store from '../../store'
import GenericDataService from '@/services/GenericDataService'

export default [
    {
        path: 'applications',
        meta: { titleDocument: "applications" },
        redirect: { name: 'ApiList' },
        component: function () {
            return import(/* webpackChunkName: "ApplicationList" */ /* webpackPrefetch: true */'../../modules/applications/views/Application.vue')
        },
        beforeEnter: (to, from, next) => {
            GenericDataService.getData('/menu/getsubmenu?module=applications').then((response) => {
                let submenu = response.data.data;
                store.dispatch('base/SET_SUB_MENU', submenu)
                next();
            })
        },
        children: [
            {
                path: 'api',
                name: 'ApiList',
                meta: { title: 'api', titleDocument: "api", rights: ["admin/connect"] },
                component: function () {
                    return import(/* webpackChunkName: "ApplicationList" */ /* webpackPrefetch: true */'../../modules/applications/views/ApiList.vue')
                },
                beforeEnter: (to, from, next) => {
                    GenericDataService.getData('/menu/getsubmenu?module=applications').then((response) => {
                        let submenu = response.data.data;
                        store.dispatch('base/SET_SUB_MENU', submenu)
                        next();
                    })
                },
            },
            {
                path: 'applications',
                name: 'ApplicationList',
                meta: { title: 'Applications', titleDocument: "applications", rights: ["admin/connectApp"] },
                component: function () {
                    return import(/* webpackChunkName: "ApplicationList" */ /* webpackPrefetch: true */'../../modules/applications/views/ApplicationList.vue')
                },
            },
            {
                path: 'api-doc',
                name: 'ApiDocList',
                meta: { title: 'apiDoc', titleDocument: "apiDoc", rights: ["admin/connect"] },
                component: function () {
                    return import(/* webpackChunkName: "ApplicationList" */ /* webpackPrefetch: true */'../../modules/applications/views/ApiDocList.vue')
                },
            },
            {
                path: 'webhook',
                name: 'WebhookList',
                meta: { title: 'webhook', titleDocument: "webhook", rights: ["admin/connect"] },
                component: function () {
                    return import(/* webpackChunkName: "ApplicationList" */ /* webpackPrefetch: true */'../../modules/applications/views/WebhookList.vue')
                },
            },
        ]
    }
]