import GenericDataService from '@/services/GenericDataService'
import vuetify from '@/plugins/vuetify'

const state = () => ({
    header: {
        title: "",
        actions: [],
    },
    menu: [],
    hasSubMenu: false,
    inSubMenu: false,
    submenu: [],
    headerActions: {},
    detailRouteFrom: "",
})

const mutations = {
    setHeaderTitle(state, value){
        state.header.title = value;
    },
    // setHeaderActions(state, value){
    //     state.header.actions = value;
    // },
    setMainMenu(state, value){
        state.menu = value;
    },
    setHomeMenu(state, value) {
        state.home = value;
    },
    setMenu(state, value) {
        state.menu = value
    },
    setSubMenu(state, value){
        if(value && value.length == 0){
            state.hasSubMenu = false
        } else {
            state.hasSubMenu = true
        }
        state.submenu = value
    },
    setHeaderActions(state, value){
        state.headerActions = value
    },
    setDetailRouteFrom(state, value){
        state.detailRouteFrom = value
    },
    setNewCountTodos(state, value){
        if(state.menu && state.menu[0] && state.menu[0].menus){
            for (const key in state.menu[0].menus) {
                if (Object.hasOwnProperty.call(state.menu[0].menus, key)) {
                    const element = state.menu[0].menus[key];
                    if(element.name == 'TodosList')
                        element.count = value
                }
            }
        }
    },
    setNewCountMessage(state, value){
        if(state.menu && state.menu[0] && state.menu[0].menus) {
            for (const key in state.menu[0].menus) {
                if (Object.hasOwnProperty.call(state.menu[0].menus, key)) {
                    const element = state.menu[0].menus[key];
                    if(element.name == 'ExchangesList')
                        element.count = value
                }
            }
        }
    },
    setInSubMenu(state, value){
        state.inSubMenu = value
    }
}

const actions = {
    async GET_MENU({commit, rootState}){
        await GenericDataService.getData("menu/v2").then((response) => {
            if(rootState.auth.user && !rootState.auth.user.hasHome){
                let menus = response.data.data;
                menus[0].menus = menus[0].menus.filter((item) => item.name != 'Home')
                commit('setMenu', menus)
            } else {
                commit('setMenu', response.data.data)
            }
            
        })
    },
    SET_IN_SUB({commit}, value){
        commit('setInSubMenu', value)
    },
    SET_SUB_MENU({commit}, value){
        commit('setSubMenu', value)
    },
    SET_HEADER_ACTIONS({commit}, value){
        commit('setHeaderActions', value)
    },
    SET_DETAIL_ROUTE_FROM({commit}, value){
        commit('setDetailRouteFrom', value)
    },
    SET_NEW_COUNT_TODOS({commit}, value){
        commit('setNewCountTodos', value)
    },
    SET_NEW_COUNT_MESSAGE({commit}, value){
        commit('setNewCountMessage', value)
    }
}

const getters = {
    
}

export default {
    namespaced: true,
    state,
    actions, 
    mutations,
    getters
}