import axios from "axios";
import store from './store';
import router from './router'

class axiosHttp {
  constructor () {
    if(process.env.NODE_ENV === 'development'){
      console.log('api.'+window.location.host)
      store.dispatch("auth/SET_API_URL", process.env.VUE_APP_API_URL);
    } else {
      store.dispatch("auth/SET_API_URL", 'https://api.'+window.location.host+'/');
    }
    this.http = axios.create({
      baseURL: store.state.auth.apiUrl,
      headers: {
        "Content-type": "application/json"
      }
    })

    this.http.interceptors.request.use(config => {
      config.headers.Token = store.state.auth.accessToken
      config.headers.Refererlexxy = window.location.href

      if(store.state.auth.currentAccountId != 0){
        config.headers.ACCOUNTID = store.state.auth.currentAccountId
      }
      if(store.state.auth.currentRoleId != 0){
        config.headers.ROLEID = store.state.auth.currentRoleId
      }
      if(router.currentRoute.params.contact_id) {
        config.headers.ContactId = router.currentRoute.params.contact_id;
      }
      if(router.currentRoute.params.operation_id) {
        config.headers.OperationId = router.currentRoute.params.operation_id;
      }
      if(store.state.lead.superleadDialog) {
        config.headers.OperationId = store.state.lead.superleadBase.operation_id.id;
      }
      
      if(store.state.auth.openTodoActionDrawer){
        config.headers.TodoId = store.state.auth.todoForDrawer.id
      } else {
        config.headers.TodoId = ""
      }

      if(store.state.auth.loggedAs && store.state.auth.loggedAs !== false) {
        config.headers.LoggedAs = store.state.auth.loggedAs.email
      } else {
        config.headers.LoggedAs = ""
      }
      // if(store.state.base.application && store.state.base.application != ""){
      //   config.headers.Application = store.state.base.application
      // } else {
      //   config.headers.Application = ""
      // }
      return config
    })
  }
}
export default new axiosHttp();